import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from 'gatsby'
import React from "react";
import MainSection from "../components/landing-page/mainSection";
import Top from "../components/landing-page/top";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsLandingPage: {
      seoMetaTags,
      pageTitle,
      companyLogo,
      companyHomepage,
      clientLogo,
      hubspotFormTitle,
      hubspotFormId,
      bulletPointSection,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <Top
        title={pageTitle}
        companyLogo={companyLogo}
        companyHomepage={companyHomepage}
      />
      <MainSection
        isLandingPage2={false}
        data={bulletPointSection}
        hubspotFormId={hubspotFormId}
        hubspotFormTitle={hubspotFormTitle}
      >
        {clientLogo && !!clientLogo.length && (
          <React.Fragment>
            <div className="md-spacing" />
            <div className="flex-container space-between v-align lp">
              {clientLogo.map(logo => (
                <div className="client-logo">
                  <img src={logo.url} alt={logo.alt} />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </MainSection>
    </Layout>
  );
};

export const query = graphql`
  query LandingPageQuery($slug: String!) {
    datoCmsLandingPage(slug: { eq: $slug }) {
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pageTitle
      companyLogo {
        url
      }
      companyHomepage
      clientLogo {
        alt
        url
      }
      hubspotFormTitle
      hubspotFormId
      bulletPointSection {
        ... on DatoCmsTitle {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsHighlight {
          highlightDetail
          model {
            apiKey
          }
        }
        ... on DatoCmsFeatureVideo {
          video {
            url
          }
          fallbackImage {
            url
          }
          model {
            apiKey
          }
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
